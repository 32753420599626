import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs';
import { environment } from '../../environments/environment';
import { HelperService } from './helper.service';
import { appVersion } from '../app.component';
import { selectIp, selectUser } from '../ngrx/data.reducer';
import { Store } from '@ngrx/store';
import { MixpanelService } from './mixpanel.service';

@Injectable({
  providedIn: 'any',
})
export class CommonService {
  ip$ = this.store.select(selectIp);
  ipData;
  userDetails: any;
  user$ = this.store.select(selectUser);
  private destroy$ = new Subject<void>();
  constructor(
    private http: HttpClient,
    private helper: HelperService,
    private store: Store,
    private mixPanelService: MixpanelService,
  ) {
    this.user$
      .pipe(distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        takeUntil(this.destroy$))
      .subscribe((user) => {
        this.userDetails = user;
      });
    this.ip$
      .pipe(distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        takeUntil(this.destroy$))
      .subscribe((ip: any) => {
        this.ipData = ip;
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  creatorBookingSlots(
    creatorId: any,
    pakageId: any,
    utcTime: any,
  ): Observable<any> {
    const url = `${environment.apiUrl}api/home/bookingSlots`;
    const body = {
      mentorId: creatorId,
      packageID: pakageId,
      fromUTCTime: utcTime,
    };
    return this.http.post<any>(url, body);
  }

  pixelAction(
    action: string,
    menteeId: number,
    eventSourceUrl: string,
    mentorName: string,
    productCategory: string,
    amount: any,
    mentorEmail: string,
    paymentGateWay,
    paymentMethod,
    noOfChats,
    productDetails,
    username
  ) {
    try {
      const url = `${environment.apiUrl}api/home/pixel-action?action=${action}&menteeId=${menteeId || 0}&ip=${this.ipData?.ip}&eventSourceUrl=${eventSourceUrl}&country=${this.ipData?.country?.toLowerCase()}&mentorName=${mentorName}&mentorEmail=${mentorEmail}&productCategory=${productCategory}&amount=${amount}&os=${this.helper.detectOS()}&version=${appVersion}&paymentGateWay=${paymentGateWay}&paymentMethod=${paymentMethod}&noOfChats=${noOfChats}`;
      this.http
        .get(url)
        .pipe(takeUntil(this.destroy$), distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)))
        .subscribe((response) => { });
      if (action == 'Product Select') {
        this.mixPanelService.trackProductEvent('Product-Selected', mentorName, productCategory, null, null, username, null, null);
      } else if (action == 'Proceed to Payment') {
        this.mixPanelService.trackProductEvent(
          'Proceed-to-Payment',
          mentorName,
          productCategory,
          productCategory?.toLowerCase() == 'bundle' ? (noOfChats + ' ' + productDetails) : productDetails,
          amount,
          username,
          null,
          null
        );
      }
    } catch (error) {
      console.error('mix panel error', error);
    }
  }

  pixelPaymentAction(
    action: string,
    eventSourceUrl: string,
    mentorName: string,
    productCategory: string,
    amount: number,
    token: any,
    paymentId?: any,
  ) {
    try {
      const eventId = Date.now();
      const TypeOf = {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Authorization', `Bearer ${token ? token : this.userDetails?.token}`),
      };
      const url = `${environment.apiUrl}api/user/pixel-payment-action?action=${action}&ip=${this.ipData?.ip}&eventSourceUrl=${eventSourceUrl}&country=${this.ipData?.country?.toLowerCase()}&mentorName=${mentorName}&productCategory=${productCategory}&amount=${amount}&eventId=${paymentId || eventId}`;
      this.http
        .get(url, TypeOf)
        .pipe(takeUntil(this.destroy$), distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)))
        .subscribe((response) => { }, (error) => { });

      // Trigger the Meta Pixel Purchase event
      if (window['fbq']) {
        window['fbq']('track', 'Purchase', { currency: 'USD', value: amount }, {eventID: paymentId || eventId});
      }
    }
    catch (error) {
      console.error('mix panel error', error);
    }
  }
}
